.tipoTrabajo {
    background-color: rgb(84, 84, 243);
}
.tipoBaja {
    background-color: rgb(253, 195, 86);
}
.tipoVacaciones {
    background-color: rgb(101, 250, 101);
}
.tipoAusencia {
    background-color: rgb(255, 93, 93);
}
.tipoNormal {
    background-color: white;
    color: black;
}