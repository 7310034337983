@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.loginForm {
    color: #3a3c47;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}

.h1 {
  margin-top: 48px;
}

.form {
  background: #fff;
  max-width: 360px;
  width: 100%;
  padding: 58px 44px;
  border: 1px solid #e1e2f0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(42, 45, 48, 0.12);
  transition: all 0.3s ease;
}

.rowLogin {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.rowLogin label {
  font-size: 13px;
  color: #8086a9;
}

.rowLogin input {
  flex: 1;
  padding: 13px;
  border: 1px solid #d6d8e6;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.2s ease-out;
}

.rowLogin input:focus {
  outline: none;
  box-shadow: inset 2px 2px 5px 0 rgba(42, 45, 48, 0.12);
}

.btnLogin {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 15px;
  transition: background 0.2s ease-out;
}

.btnLogin:hover {
  background: #4699f1;
}

@media(max-width: 458px) {
  
  .formLogin {
    margin: 0 18px;
  }
  
  .form {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 20px 0;
  }

}